export default {
  "navbar": {
    "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery"])},
    "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images"])},
    "videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videos"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])}
  },
  "homePage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max gallery!"])},
    "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery"])},
    "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images"])},
    "videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videos"])}
  },
  "pageNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page doesn't exist!"])},
  "imagesPage": {
    "headers": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation date"])},
      "modified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modification date"])}
    }
  }
}