<template>
    <div class="columns is-flex is-justify-content-center">
        <div class="column is-half">
            <h1 class="row title">Log in</h1>
            <form @submit.prevent="submitForm">
                <div class="field">
                    <label>Username</label>
                    <div class="control">
                        <input type="text" class="input" v-model="username">
                    </div>
                </div>
                <div class="field">
                    <label>Password</label>
                    <div class="control">
                        <input type="password" class="input" v-model="password">
                    </div>
                </div>

                <div class="notification is-danger" v-if="errors.length">
                    <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                </div>

                <div class="field">
                    <div class="control">
                        <button class="button is-dark">Log in</button>
                    </div>
                </div>
                <hr>
                Or <router-link to="/signup">click here</router-link> to sign up!
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'LogIn',
    data() {
        return {
            username: '',
            password: '',
            errors: []
        }
    },
    mounted() {
    },
    methods: {
        async submitForm() {
            axios.defaults.headers.common["Authorization"] = ""
            localStorage.removeItem("token")
            const formData = {
                username: this.username,
                password: this.password
            }
            this.$store.commit('setIsLoading', true)
            await axios.post("/api/token/login", formData).then(response => {
                const token = response.data.auth_token
                this.$store.commit('setToken', token)
                
                axios.defaults.headers.common["Authorization"] = "token " + token
                localStorage.setItem("token", token)

                const toPath = this.$route.query.to || '/'
                this.$router.push(toPath)
            }).catch(error => {
                if (error.response) {
                    for (const property in error.response.data) {
                        this.errors.push(`${error.response.data[property]}`)
                    }
                } else {
                    this.errors.push('Something went wrong. Please try again')
                }
            }).finally(() => {
                this.$store.commit('setIsLoading', false)
            })
        }
    }
}
</script>