export default {
  "navbar": {
    "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galeria"])},
    "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdjęcia"])},
    "videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filmy"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaloguj się"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj"])}
  },
  "homePage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galeria Maksymiliana!"])},
    "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galeria"])},
    "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdjęcia"])},
    "videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filmy"])}
  },
  "pageNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strona nie istnieje!"])},
  "imagesPage": {
    "headers": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tytuł"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdjęcie"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data utworzenia"])},
      "modified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data modyfikacji"])}
    }
  }
}