<template>
    <div class="hero is-halfheight">
        <div class="hero-body is-flex is-justify-content-center">
        </div>
    </div>
</template>
<script>
export default {
}
</script>
<style scoped>
    
</style>